$darkblue: #001739;
$darkblueshade: #000f25;
$darkbluetint: #1a2e4d;
$digitalturquoise: #00e1d2;
$digitalturquoiseshade: #00f5e4;
$darkturquoise: #18b7b7;
$darkturquoiseshade: #16a6a6;
$midgrey: #ebeef0;

/* General */
$fontSize: 14px;
$bodyBgColor: #001739;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 5px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;
$focusShadowColor: #8dcdff;

/* Menu Common */
$menuitemBadgeBgColor: $darkblue;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: $digitalturquoise;

/* Menu Light */
$menuBgColorFirst: $digitalturquoise;
$menuBgColorLast: $digitalturquoise;
$menuitemColor: $darkblue;
$menuitemHoverColor: $darkturquoiseshade;
$menuitemActiveColor: $darkturquoiseshade;
$menuitemActiveBgColor: $digitalturquoiseshade;
$menuitemBorderColor: rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst: $darkblue;
$menuDarkBgColorLast: $darkblue;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: $darkturquoise;
$menuitemDarkActiveColor: $darkturquoise;
$menuitemDarkActiveBgColor: $darkblueshade;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor: $digitalturquoise;
$topbarRightBgColor: $digitalturquoise;
$topbarItemBadgeBgColor: $darkblue;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: $digitalturquoiseshade;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #ffffff;
