.bee-global-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 250px;
  height: 150px;
  margin-top: -125px;
  margin-left: -75px;
  z-index: 499;
  background-color: rgba(0, 23, 57, 0.7);
  border-radius: 10px;
  text-align: center;
  box-shadow: 3px 3px 5px 1px rgba(37, 49, 65, 0.5);
  color: #00e1d2;
}

.bee-global-loading-hidden {
  display: none;
}

.bee-global-loading-header {
  margin-top: 10px !important;
}
