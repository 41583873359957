.bee-footer {
  text-align: center;
  margin: 4px 0px;
  padding: 6px;
  background-color: rgba(24, 183, 183, 0.6);
}
.bee-footer-link {
  text-decoration: none;
  padding: 4px;
  margin: 0px 16px;
  color: #828890;
}
