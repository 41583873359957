.ep-detail-descr {
  hyphens: auto;
  text-align: justify;
  font-style: italic;
  color: #18b7b7;
}
.ep-detail-number {
  color: darkgray;
  hyphens: auto;
  text-align: justify;
}
.ep-detail-content {
  hyphens: auto;
  text-align: justify;
}
.ep-detail-header {
  font-weight: bold;
  hyphens: auto;
  text-align: justify;
}

.bee-ep-detail table {
  table-layout: auto !important;
}

.bee-ep-detail {
  margin: 5px 25px;
  margin-bottom: 15px;
}

.bee-ep-detail td {
  padding: 0px !important;
}
