.bee-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.bee-headline {
  color: #00e1d2;
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.in_header_btn span {
  display: none;
}

.in_header_btn i {
  margin-right: 0px;
}

.in_header_btn {
  height: 100%;
}

.in_header_status {
  color: #828890;
}
