#epsearch-btn-search {
  margin-left: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#epsearch-btn-clear {
  margin: 0px 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.splitting-span {
  padding: 10px;
}

.flex-search {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ebeef0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: large;
}
