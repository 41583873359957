.bee-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0.8;
  background: #001739;
}
