.bee-two-sided-btn {
  width: 100px;
  display: flex;
  margin-left: 16px;
}

#bee-two-btn-left {
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-color: #00e1d2;
  border-width: 1px;
}
#bee-two-btn-right {
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-color: #00e1d2;
  border-width: 1px;
}
