.bee-option-container {
  width: 50%;
  min-width: 750px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.bee-color-accent {
  color: #18b7b7;
}

.bee-text-bold {
  font-weight: bold;
  font-size: medium;
}

.p-checkbox-box.p-highlight {
  border-color: #00e1d2 !important;
  background-color: #00e1d2 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #00e1d2 !important;
  background-color: #00e1d2 !important;
}

.bee-spacing-top-small {
  margin-top: 5px !important;
}

.bee-spacing {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}

th {
  text-align: left;
}

td > label {
  padding-right: 8px;
}

td {
  padding-bottom: 5px;
}

#bee-close-settings-btn {
  width: fit-content;
  align-self: flex-end;
  margin-top: 4px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #001739 !important;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #00e1d2 !important;
}
