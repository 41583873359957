.content-container {
  display: flex;
  flex-direction: column;
}

#result-container {
  border-radius: 5px;
  background-color: whitesmoke;
  position: relative;
  flex-grow: inherit;
}

#no-result-container {
  text-align: center;
  margin: 24px;
  padding: 24px;
}

.table_btn_icon {
  display: flex;
}

.in_table_btn span {
  display: none;
}
.in_table_btn i {
  margin-right: 0px;
}

.in_table_btn {
  padding-top: 4px;
  padding-bottom: 4px;
}

.bee-group-footer {
  color: transparent;
  background-color: transparent;
}

.flex {
  display: flex;
  align-items: center;
}

.icon_in_table_subheader {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3px;
  margin-right: 3px;
}

.table-data-content {
  hyphens: auto;
  text-align: justify;
}

.table-data-number {
  color: darkgray;
}

.p-row-toggler {
  border-width: 0px;
  background: transparent;
  height: 30px;
  width: 30px;
  margin: auto;
  display: flex;
}

/*OVERRIDE FRAMEWORK*/
.p-rowgroup-header {
  background-color: #001739 !important;
  color: whitesmoke !important;
}

.p-datatable-header {
  font-size: 18px !important;
}
