.content-container {
  padding: 16px;
  margin: 16px;
  flex-grow: 1;
  width: auto;
}

.bg {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header,
.footer {
  width: 100%;
}

.flex-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
