.bee-btm-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: inline-block;
  display: flex;
}

.bee-go-msg {
  background-color: rgba(0, 23, 57, 0.7);
  margin: auto;
  margin-bottom: 25px;
  padding: 5px 50px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 3px 3px 5px 1px rgba(37, 49, 65, 0.5);
  color: #00e1d2;
}
